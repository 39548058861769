import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ClientAgentNomination = () => {
  return (
    <Layout>
      <Seo
        title="Client Agent Nomination"
        description={`Learn how to access and complete the onboarding wizard in Synkli. Follow simple steps for new and existing clients to set up or update your profile and start using Synkli.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`client-agent-nomination`} />
    </Layout>
  )
}

export default ClientAgentNomination
